import { AfterViewInit, Component, inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgIf } from '@angular/common';
import { BillingForm, BillingFormVm, BillingToForm } from './billing-form.vm';
import { FormCheckBoxComponent } from '../form/controls/checkbox';
import { FormInputComponent } from '../form/controls/input';
import { FormSelectComponent } from '../form/controls/select';
import { tap } from 'rxjs/operators';
import { FormGroupValue } from '../form.utils';
import { filter, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppStore } from '@aaa/emember/store-types';
import { getBillingFormErrors } from '../../../store/form-messages';

@Component({
  standalone: true,
  selector: 'ava-billing-form',
  template: `
    @if (errorMessages$ | async; as errorMessages) {
      <div [formGroup]="formGroup" class="ava-column ava-gap-2">
        <ava-form-checkbox formControlName="sameBillingAddress" formLabel="Billing address same as household address" />

        @if (!formGroup?.controls?.sameBillingAddress?.value) {
          <div formGroupName="billingTo" class="ava-column ava-gap-2">
            <div class="ava-column ava-gap-2">
              <ava-form-input
                [errorMessages]="errorMessages.firstName"
                placeholder="First Name"
                formControlName="firstName"
              />
              <ava-form-input
                [errorMessages]="errorMessages.lastName"
                class="ava-flex"
                placeholder="Last Name"
                formControlName="lastName"
              />
              <ava-form-input
                [errorMessages]="errorMessages.address1"
                placeholder="Address 1"
                formControlName="address1"
              />
              <ava-form-input placeholder="Address 2" formControlName="address2" />
              <ava-form-input [errorMessages]="errorMessages.city" placeholder="City" formControlName="city" />
            </div>
            <div class="ava-row ava-gap-1">
              <ava-form-select
                [errorMessages]="errorMessages.state"
                [options]="states"
                [showSearch]="true"
                placeholder="State"
                formControlName="state"
                class="ava-flex"
              />
              <ava-form-input
                [errorMessages]="errorMessages.zipcode"
                placeholder="Zip Code"
                formControlName="zipcode"
                class="ava-flex"
              />
            </div>
          </div>
        }
      </div>
    }
  `,
  imports: [ReactiveFormsModule, FormCheckBoxComponent, NgIf, FormInputComponent, FormSelectComponent, AsyncPipe],
})
export class BillingFormComponent implements OnDestroy, AfterViewInit, OnChanges {
  store = inject(Store<AppStore>);
  billingFormVm = inject(BillingFormVm);
  controlContainer = inject(ControlContainer);
  errorMessages$ = this.store.select(getBillingFormErrors);
  @Input() states = [];
  @Input() defaultBillingTo: Partial<FormGroupValue<BillingToForm>> = {};
  get formGroup(): FormGroup<BillingForm> {
    if (this.controlContainer) {
      return this.controlContainer.control as FormGroup<BillingForm>;
    }
    return this.billingFormVm.create();
  }

  get sameBillingAddress() {
    return this.formGroup.controls.sameBillingAddress;
  }

  get billingTo() {
    return this.formGroup.controls.billingTo;
  }

  alive$ = new Subject();

  ngAfterViewInit() {
    this.sameBillingAddress.valueChanges
      .pipe(
        filter(() => Object.keys(this.defaultBillingTo).length > 0),
        tap((checked) => checked && this.billingTo.patchValue(this.defaultBillingTo)),
        takeUntil(this.alive$),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultBillingTo']) {
      this.billingTo.patchValue(this.defaultBillingTo);
    }
  }

  ngOnDestroy() {
    this.alive$.next(null);
    this.alive$.complete();
  }
}
